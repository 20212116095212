<template>
  <div>
    <div class="display-1">Заболевания (состояния)</div>
    <NosologiaProjectCard
      v-for="nos in nosologies"
      :key="nos.id"
      :projectNosologia="nos"
      :projectName="projectName"
      @update="throwUpdate"
    />
    <v-btn text @click="selectNosologies"
      ><v-icon class="mr-1">mdi-playlist-edit</v-icon>Выбрать заболевания
      (состояния)</v-btn
    >
  </div>
</template>

<script>
import { SELECT_NOSOLOGIES } from '@/store/const/path'
import NosologiaProjectCard from '@/components/project/NosologiaProjectCard.vue'

export default {
  props: ['nosologies', 'projectName', 'stageId'],
  components: {
    NosologiaProjectCard,
  },
  methods: {
    selectNosologies() {
      this.$router.push(`${this.$route.path}/${SELECT_NOSOLOGIES}`)
    },
    throwUpdate() {
      this.$emit('update')
    },
  },
}
</script>
