<template>
  <div>
    <span class="font-weight-bold mr-4">{{ label }}:</span>
    <span v-if="value"> {{ value }} </span>
    <span v-else> (нет) </span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['label', 'value'],
}
</script>
