export const dialogMixin = {
  data() {
    return {
      dialog: false,
      confirm: false,
    }
  },
  methods: {
    onOpen() {
      this.confirm = false
      this.dialog = true
    },
    onClose() {
      this.dialog = false
    },
  },
}
