<template>
  <div class="alert">
    <v-icon color="rgba(251, 140, 0, 1)" class="mr-4">mdi-alert</v-icon>
    <div class="mr-auto">
      {{ text }}
    </div>
    <v-btn
      x-small
      @click="onCloseAlert"
      :loading="loading"
      :disabled="loading"
      >{{ buttonText }}</v-btn
    >
  </div>
</template>

<script>
// Сделал свой alert и написал для него CSS, т.к. у v-alert были проблемы со стилями лоадера на кнопке закрытия

export default {
  props: ['text', 'buttonText'],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    onCloseAlert() {
      this.loading = true
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.alert {
  color: rgba(251, 140, 0, 1);
  background-color: rgba(251, 140, 0, 0.12);
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 16px;
  border-radius: 4px;
}
</style>
