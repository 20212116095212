<template>
  <div>
    <PageHeader :text="projectName">
      <EditButton label="Редактировать" />
      <DeleteProjectWithDialog
        :projectName="projectName"
        :projectId="projectId"
      />
    </PageHeader>

    <ProjectDescription class="mb-12" :project="project" />

    <NosologiaProjectBlock
      class="mb-12"
      :nosologies="nosologies"
      :projectName="projectName"
      @update="throwUpdate"
    />

    <ExpertProjectBlock class="mb-12" :users="projectUsers" />

    <div v-if="nextStage.disabled" class="body-2 red--text">
      {{ nextStage.errorMessage }}
    </div>
    <v-btn
      color="primary"
      @click="onNextStageCLick"
      :disabled="nextStage.disabled"
      >Отправить в работу</v-btn
    >
  </div>
</template>

<script>
import NosologiaProjectBlock from '@/components/project/NosologiaProjectBlock.vue'
import ExpertProjectBlock from '@/components/project/ExpertProjectBlock.vue'
import DeleteProjectWithDialog from '@/components/buttons/DeleteProjectWithDialog'
import PageHeader from '@/components/common/PageHeader.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import ProjectDescription from '@/components/project/ProjectDescription.vue'

import { toNextStage } from '@/lib/requestsAPI'

import { routeParams } from '@/mixins/routeParams'
import { sendDataWithToastNoRedirect } from '@/mixins/sendDataWithToastNORedirect'

import { USER_GVS_ID } from '@/store/const/userRoles'

export default {
  props: ['project'],
  components: {
    DeleteProjectWithDialog,
    PageHeader,
    EditButton,
    NosologiaProjectBlock,
    ExpertProjectBlock,
    ProjectDescription,
  },
  mixins: [routeParams, sendDataWithToastNoRedirect],
  computed: {
    projectName() {
      return this.project.name
    },
    nosologies() {
      return this.project.projectNosologies
    },
    projectUsers() {
      return this.project.users
    },
    nextStage() {
      const { projectNosologies } = this.project
      const res = { errorMessage: '', disabled: true }

      if (projectNosologies.length === 0) {
        res.errorMessage =
          'В проекте должно быть хотя бы одно заболевание (состояние)'
        return res
      }

      for (let n of projectNosologies) {
        const { name } = n.nosology

        if (n.criteria_count === 0) {
          res.errorMessage = `Не заданы критерии для заболевания (состояния) «${name}»`
          return res
        }

        const hasSomeGVS = n.userProjectNosologies.some(
          upn => upn.user.role_id === USER_GVS_ID
        )
        if (!hasSomeGVS) {
          res.errorMessage = `Не задан ни один ГВС для заболевания (состояния) «${name}»`
          return res
        }
      }

      res.disabled = false
      return res
    },
  },
  methods: {
    onNextStageCLick() {
      this.sendDataWithToastNoRedirect({
        action: toNextStage,
        data: this.projectId,
        successMessage: 'Отправлен в работу',
        errorMessage: 'Ошибка отправки в работу',
        onSuccess: () => this.throwUpdate(),
      })
    },
    throwUpdate() {
      this.$emit('update')
    },
  },
}
</script>

<style scoped>
.tip {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
}
</style>
