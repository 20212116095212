import callApi from '@/lib/callAPI'
import { GET } from '@/store/const/apiMethods'
import ExcelJS from 'exceljs'
import { CRITERIA_STATUS } from '@/store/const/criteriaStatus'
import { USER_GVS_ID, USER_ROLES } from '@/store/const/userRoles'

export default class Excel {
  /* COMMON */
  constructor({ link, fileName, creatorName }) {
    this.link = link
    this.fileName = fileName || 'Безымянный'
    this.creatorName = creatorName || 'Безымянный автор'
    this.workbook = {}
    this.sheet = {}
    this.data = {}
    // только для ответов
    this.TABLE_HEADERS_AT_ROW = 5
  }

  delay(ms) {
    return new Promise(resolve => {
      setTimeout(() => resolve(true), ms)
    })
  }

  async getData() {
    this.data = await callApi(GET, this.link)
  }

  create() {
    this.workbook = new ExcelJS.Workbook()
    this.workbook.creator = this.creatorName
    this.workbook.created = new Date()
    this.sheet = this.workbook.addWorksheet('Лист 1')
  }

  async download() {
    const data = await this.workbook.xlsx.writeBuffer()
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = this.fileName
    anchor.click()
    window.URL.revokeObjectURL(url)
  }

  /* PROJECT */
  // Отличается вызываемой функцией
  async downloadProject() {
    await this.getData()
    this.create()
    this.fillExcelWithProject()
    this.download()
    return true
  }

  fillExcelWithProject() {
    /*
      - Наверное было бы удобно иметь отдельно объект с ключами. Все затычки в этом методе от того что его нет
      - Ключи можно взять из любого элемента, беру из нулевого. Они же заголовки первой строки
      - Зашивать названия колонок не круто, но по другому пока никак
      - Для получения данных в строке можно было бы использовать Object.values(row), но в теории может сломаться порядок поэтому по ключу
    */
    const DATETIME_COLUMNS = ['Дата/время создания', 'Крайний срок']
    const headers = Object.keys(this.data[0])

    let rowIndex = 1

    const headerRow = this.sheet.getRow(rowIndex)
    headerRow.values = headers
    headerRow.font = { bold: true }
    DATETIME_COLUMNS.forEach(h => {
      const colNumber = headers.indexOf(h) + 1
      if (colNumber) this.sheet.getColumn(colNumber).width = 12
    })

    rowIndex += 1

    this.data.forEach(row => {
      this.sheet.getRow(rowIndex).values = headers.map(h =>
        DATETIME_COLUMNS.includes(h) ? new Date(row[h]) : row[h]
      )
      rowIndex += 1
    })
  }

  /* ANSWERS */
  // Отличается вызываемой функцией
  async downloadAnswers() {
    await this.getData()
    this.create()
    this.fillExcelWithAnswers()
    this.download()
    return true
  }

  fillExcelWithAnswers() {
    this.createMainHeader()
    this.setWidth()
    this.createTableHeaders()
    this.createRows()
  }

  createMainHeader() {
    const { nosology } = this.data.projectNosology
    const { user } = this.data

    this.sheet.getCell('A1').value = 'Заболевание (состояние)'
    this.sheet.getCell('A1').style = { font: { bold: true } }
    this.sheet.getCell('B1').value = nosology.name

    this.sheet.getCell('A2').value = 'МКБ10'
    this.sheet.getCell('A2').style = { font: { bold: true } }
    this.sheet.getCell('B2').value = nosology.mkb10_codes

    this.sheet.getCell('A3').value = USER_ROLES[user.role_id].display
    this.sheet.getCell('A3').style = { font: { bold: true } }
    this.sheet.getCell('B3').value = user.fio
  }

  setWidth() {
    this.sheet.columns = [
      { width: 40 },
      { width: 40 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ]
  }

  createTableHeaders() {
    const headers = this.sheet.getRow(this.TABLE_HEADERS_AT_ROW)
    headers.style = { font: { bold: true } }
    headers.values = USER_ROLES[this.data.user.role_id].excelHeaders
  }

  createRows() {
    let rowIndex = this.TABLE_HEADERS_AT_ROW + 1
    const criteries = this.data.userCriterionStages
    criteries.forEach(criteria => {
      const row = this.sheet.getRow(rowIndex)
      const { data } = criteria
      if (this.data.user.role_id === USER_GVS_ID) {
        row.values = [
          criteria.criterionStage.criterion.name,
          criteria.criterionStage.criterion.name_203n,
          // затычка с ? на случай если пытаются выгрузить ответы из свеже созданной юзер-проект-нозологии
          data?.isStationar ? 'ДА' : '',
          data?.isAmbulatory ? 'ДА' : '',
          data?.criteriaStatus ? CRITERIA_STATUS[data.criteriaStatus].name : '',
          data?.criteriaNecessity ? data.criteriaNecessity : '',
        ]
      } else {
        row.values = [
          criteria.criterionStage.criterion.name,
          criteria.criterionStage.criterion.name_203n,
          // затычка с ? на случай если пытаются выгрузить ответы из свеже созданной юзер-проект-нозологии
          data?.criteriaNecessity ? data.criteriaNecessity : '',
          data?.criteriaAccessability ? data.criteriaAccessability : '',
          // У региона нет целесообразности, но она просто останется пустой
          data?.criteriaExpediency ? data.criteriaExpediency : '',
        ]
      }
      rowIndex += 1
    })
  }
}
