<template>
  <div>
    <v-btn text small @click="onOpen"
      ><v-icon class="mr-1">mdi-delete</v-icon>Удалить</v-btn
    >

    <v-dialog v-model="dialog" max-width="600" @click:outside="onClose">
      <v-card>
        <v-card-title class="headline"
          >Удалить проект: {{ projectName }}?</v-card-title
        >
        <v-card-text>
          Проект еще не отправлен в работу, поэтому его можно удалить
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="onDelete">Удалить</v-btn>
          <v-btn @click="onClose">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { deleteProject } from '@/lib/requestsAPI'

import { sendFormWithToastAndRedirect } from '@/mixins/sendFormWithToastAndRedirect'
import { dialogMixin } from '@/mixins/dialogMixin'

import { ADMIN, PROJECTS } from '@/store/const/path'

export default {
  props: ['projectName', 'projectId'],
  mixins: [dialogMixin, sendFormWithToastAndRedirect],
  methods: {
    onDelete() {
      this.sendFormWithToastAndRedirect({
        action: deleteProject,
        data: this.projectId,
        successPath: `/${ADMIN}/${PROJECTS}`,
        successMessage: `${this.projectName} удален!`,
        errorMessage: `Не получилось удалить проект ${this.projectName}`,
      })
    },
  },
}
</script>

<style scoped>
.v-input--checkbox >>> .v-input__slot {
  align-items: flex-start !important;
}
</style>
