export const _ONE = 1
export const _TWO_FOR = 2
export const _OTHER = 999

export const _dict = {
  проект: {
    [_ONE]: 'проект',
    [_TWO_FOR]: 'проекта',
    [_OTHER]: 'проектов',
  },
  профиль: {
    [_ONE]: 'профиль',
    [_TWO_FOR]: 'профиля',
    [_OTHER]: 'профилей',
  },
  пользователь: {
    [_ONE]: 'пользователь',
    [_TWO_FOR]: 'пользователя',
    [_OTHER]: 'пользователей',
  },
  критерий: {
    [_ONE]: 'критерий',
    [_TWO_FOR]: 'критерия',
    [_OTHER]: 'критериев',
  },
  заболевание: {
    [_ONE]: 'заболевание',
    [_TWO_FOR]: 'заболевания',
    [_OTHER]: 'заболеваний',
  },
  состояние: {
    [_ONE]: 'состояние',
    [_TWO_FOR]: 'состояния',
    [_OTHER]: 'состояний',
  },
}
