<template>
  <div>
    <PageHeader :text="project.name">
      <EditButton label="Редактировать" />
    </PageHeader>

    <ProjectDescription :project="project" />

    <v-data-table
      class="mb-8"
      :headers="headers"
      :items="data"
      item-key="id"
      hide-default-footer
      disable-pagination
      @click:row="onRowClick"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-progress-linear
          v-model="item.status"
          class="mb-2"
        ></v-progress-linear>
        <div class="d-flex grey--text">
          <div class="mr-2">{{ item.status }}%</div>
          <div v-if="item.status === 100">
            <div v-if="item.done">
              <v-icon class="mr-1">mdi-check-all</v-icon>
              <span>ГВС отправил ответы</span>
            </div>
            <div v-else>
              <v-icon class="mr-1" small>mdi-alert</v-icon>
              <span>ГВС ответил на все вопросы, но не отправил ответы</span>
            </div>
          </div>
        </div>
      </template>
    </v-data-table>

    <div v-if="!readyForNextStage" class="mb-4">
      <div>
        Еще не все ГВС ответили полностью или не подтвердили свои ответы.
      </div>
      <v-checkbox
        v-model="forceNextStage"
        label="Завершить этап принудительно. Не ждать всех ответов ГВС"
        color="error"
        hide-details
        class="ma-0 pa-0"
      ></v-checkbox>
    </div>
    <v-btn
      color="primary"
      @click="onNextStageCLick"
      :disabled="!forceNextStage && !readyForNextStage"
      >Перевести на следующий этап</v-btn
    >
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import ProjectDescription from '@/components/project/ProjectDescription.vue'

import { toNextStage, toNextStageForce } from '@/lib/requestsAPI'
import { calculatePercent } from '@/lib/percent.js'

import { routeParams } from '@/mixins/routeParams'
import { sendDataWithToastNoRedirect } from '@/mixins/sendDataWithToastNORedirect'

import { ADMIN, PROJECTS, PROJECT_SHORT, USER_PR_NOS } from '@/store/const/path'
import { USER_GVS_ID } from '@/store/const/userRoles'

export default {
  data() {
    return {
      forceNextStage: false,
      headers: [
        { text: 'КР', value: 'nosologiaName' },
        { text: 'ГВС', value: 'gvsFIO' },
        { text: 'Готовность', value: 'status' },
      ],
    }
  },
  props: ['project'],
  components: {
    PageHeader,
    ProjectDescription,
    EditButton,
  },
  mixins: [routeParams, sendDataWithToastNoRedirect],
  computed: {
    readyForNextStage() {
      return this.data.map(d => d.done).every(a => a)
    },
    nosologies() {
      return this.project.projectNosologies
    },
    projectUsers() {
      return this.project.users
    },
    data() {
      const preparedData = []
      this.nosologies.forEach(nos => {
        const nosologiaName = nos.nosology.name
        nos.userProjectNosologies.forEach(upn => {
          if (upn.user.role_id === USER_GVS_ID) {
            preparedData.push({
              nosologiaName,
              id: upn.id,
              gvsFIO: upn.user.fio,
              done: upn.is_completed,
              status: calculatePercent(
                upn.answered_criteria_count,
                upn.common_criteria_count
              ),
            })
          }
        })
      })
      return preparedData
    },
  },
  methods: {
    onNextStageCLick() {
      this.sendDataWithToastNoRedirect({
        action: this.forceNextStage ? toNextStageForce : toNextStage,
        data: this.projectId,
        successMessage: 'Отправлен в работу',
        errorMessage: 'Ошибка отправки в работу',
        onSuccess: () => this.throwUpdate(),
      })
    },
    throwUpdate() {
      this.$emit('update')
    },
    onRowClick(item) {
      this.$router.push(
        `/${ADMIN}/${PROJECTS}/${PROJECT_SHORT}-${this.project.id}/${USER_PR_NOS}-${item.id}`
      )
    },
  },
}
</script>

<style scoped>
.v-data-table {
  cursor: pointer;
}
</style>
