<template>
  <v-btn text small @click="onClick"
    ><v-icon class="mr-1">mdi-pencil</v-icon> {{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    label: {
      default: 'Редактировать',
    },
  },
  methods: {
    onClick() {
      this.$router.push(`${this.$route.path}/edit`)
    },
  },
}
</script>
