<template>
  <div>
    <div class="display-1 mr-4 mb-2">ГВС в проекте</div>
    <div class="mb-2">
      <div>
        Это список всех ГВС на этом проекте. Отдельные заболевания (состояния)
        уходят каждому отдельно выбранному эксперту.
      </div>
      <div>
        НМИЦов для проекта можно будет выбрать на следующем этапе, когда ГВС
        закончат работу.
      </div>
    </div>
    <ul v-if="gvsUsers.length" class="mb-2">
      <li v-for="user in gvsUsers" :key="user.id">{{ user.fio }}</li>
    </ul>
    <div v-else>Нет ГВС на проекте</div>
  </div>
</template>

<script>
import { USER_GVS_ID } from '@/store/const/userRoles'

export default {
  props: ['users'],
  computed: {
    gvsUsers() {
      return this.users.filter(u => u.role_id === USER_GVS_ID)
    },
  },
}
</script>
