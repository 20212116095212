<template>
  <v-card class="mb-2 pa-4">
    <div class="body-1 mb-2">{{ nosologia.name }}</div>

    <div class="mb-4">
      <div class="mb-2">
        <TitleAndDataLine label="ГВС" :value="gvsNames" class="grey--text" />
        <TitleAndDataLine label="НМИЦ" :value="nmicNames" />
        <TitleAndDataLine label="Критерии" :value="criteriesText" />
      </div>

      <Alert
        v-if="showNMICAlert"
        class="mb-2"
        text="НМИЦ выбраны автоматически. Проверьте все ли верно?"
        buttonText="НМИЦ, да!"
        @close="closeAlertNMIC"
      />

      <Alert
        v-if="showCriteriaAlert"
        class="mb-2"
        text="Критерии для следующего этапа отобраны автоматически. Проверьте правильно ли?"
        buttonText="Критерии, да!"
        @close="closeAlertCriteria"
      />

      <v-btn class="mr-4" text small @click="onEditNMIC">
        <v-icon small class="mr-1">mdi-pencil</v-icon>
        <span>Выбрать НМИЦ</span>
      </v-btn>

      <v-btn text small @click="onCheckCriteria">
        <v-icon class="mr-1">mdi-list-status</v-icon>
        <span>Отобрать критерии</span>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'
import Alert from '@/components/common/Alert.vue'

import { routeParams } from '@/mixins/routeParams'
import { sendDataWithToastNoRedirect } from '@/mixins/sendDataWithToastNORedirect'

import { markCriteriaAsChecked, markUsersAsChecked } from '@/lib/requestsAPI'
import { declineByNumber } from '@/lib/declineByNumber'

import { USER_NMIC_ID, USER_GVS_ID } from '@/store/const/userRoles'

import {
  NOSOLOGIA_SHORT,
  SELECT_NMIC,
  SELECT_CRITERIA_PASS,
} from '@/store/const/path'

export default {
  props: ['projectNosologia'],
  mixins: [routeParams, sendDataWithToastNoRedirect],
  components: {
    TitleAndDataLine,
    Alert,
  },
  computed: {
    nosologia() {
      return this.projectNosologia.nosology
    },
    users() {
      return this.projectNosologia.userProjectNosologies.map(upn => upn.user)
    },
    nmicNames() {
      return this.getNames(USER_NMIC_ID)
    },
    gvsNames() {
      return this.getNames(USER_GVS_ID)
    },
    showNMICAlert() {
      return this.projectNosologia.is_users_check_needed
    },
    showCriteriaAlert() {
      return this.projectNosologia.is_criteria_check_needed
    },
    criteriesText() {
      const pass = this.projectNosologia.passing_criteria_count
      const total = this.projectNosologia.criteria_count
      const passText = declineByNumber('критерий', pass)
      const totalText = declineByNumber('критерий', total)

      if (pass === 0) {
        this.$emit('zero-alert', this.nosologia.name)
        return `На следующий этап не проходит ни один критерий из ${total}`
      }

      if (pass === total) {
        return `На следующий этап проходят все ${total} ${totalText}`
      }

      return `На следующий этап проходит ${pass} ${passText} из ${total}`
    },
  },
  methods: {
    getNames(roleId) {
      return this.users
        .filter(u => u.role_id === roleId)
        .map(u => u.fio)
        .join(', ')
    },
    closeAlertNMIC() {
      this.markAsChecked(markUsersAsChecked, 'НМИЦ')
    },
    closeAlertCriteria() {
      this.markAsChecked(markCriteriaAsChecked, 'Критерии')
    },
    markAsChecked(action, subject) {
      this.sendDataWithToastNoRedirect({
        action: () => action(this.projectNosologia.id),
        onSuccess: this.onSuccess,
        successMessage: false,
        errorMessage: `Не удалось сбросить отметку «Проверить ${subject}» с ${this.projectNosologia.id}`,
      })
    },
    onSuccess() {
      this.$emit('update')
    },
    onEditNMIC() {
      this.$router.push(
        `${this.$route.path}/${NOSOLOGIA_SHORT}-${this.nosologia.id}/${SELECT_NMIC}`
      )
    },
    onCheckCriteria() {
      this.$router.push(
        `${this.$route.path}/${NOSOLOGIA_SHORT}-${this.nosologia.id}/${SELECT_CRITERIA_PASS}`
      )
    },
  },
}
</script>
