<template>
  <v-btn color="primary" @click="onExcelDownload"
    ><v-icon class="mr-1">mdi-download</v-icon>Получить итоговый отчет
  </v-btn>
</template>

<script>
import { routeParams } from '@/mixins/routeParams'
import Excel from '@/lib/excel.js'
import { API_PROJECTS_REPORT } from '@/store/const/apiPaths'

export default {
  mixins: [routeParams],
  props: ['projectName'],
  methods: {
    async onExcelDownload() {
      const excel = new Excel({
        link: `${API_PROJECTS_REPORT}/${this.projectId}`,
        fileName: `${this.projectName}.xlsx`,
        creatorName: this.$store.getters.getLoggedUser.fio,
      })
      excel.downloadProject()
    },
  },
}
</script>
