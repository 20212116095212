import { _ONE, _TWO_FOR, _OTHER, _dict } from '@/lib/declensionsDictionary'

/**
 * Returns the word declined by number if the word is in the dictionary or the word itself if it is not in the dictionary
 * @param {string} word
 * @param {number} amount
 * @returns {string}
 */
export function declineByNumber(word, amount = 1) {
  if (!word) {
    console.error('Should pass word to declineByNumber')
    return undefined
  }

  if (!Number.isInteger(amount)) {
    console.error('Amount should be integer in declineByNumber')
    return word
  }

  if (!([word] in _dict)) {
    console.error('Unknown word in declineByNumber')
    return word
  }

  try {
    return _dict[word][_rest(amount)]
  } catch (e) {
    console.error('Error in declineByNumber')
    console.error(e)
    return word
  }
}

function _rest(val) {
  const rest100 = val % 100
  if (rest100 % 100 >= 11 && rest100 <= 14) {
    return _OTHER
  }
  const rest10 = val % 10
  if (rest10 === 1) {
    return _ONE
  }
  if (rest10 >= 2 && rest10 <= 4) {
    return _TWO_FOR
  }
  return _OTHER
}
