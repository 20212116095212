<template>
  <div>
    <TitleAndDataLine label="Описание" :value="project.description" />
    <TitleAndDataLine label="Срок" :value="readableDeadline" />
    <TitleAndDataLine label="Статус" :value="readableStatus">
      <ShowStages class="ml-4" :stageId="stageId" />
    </TitleAndDataLine>
  </div>
</template>

<script>
import ShowStages from '@/components/project/ShowStages.vue'
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'

import { PROJECT_STAGES } from '@/store/const/projectStages'

import { formatDate } from '@/lib/datetime'

export default {
  props: ['project'],
  components: { ShowStages, TitleAndDataLine },
  computed: {
    readableDeadline() {
      return formatDate(this.project.time_deadline)
    },
    stageId() {
      return this.project.stage_id
    },
    readableStatus() {
      return PROJECT_STAGES[this.stageId].name
    },
  },
}
</script>
