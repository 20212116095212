export function formatDate(date) {
  if (!date) return ''
  if (typeof date === 'string')
    return new Date(date).toISOString().substr(0, 10)
  return date.toISOString().substr(0, 10)
}

export function addDays(dateObject, days) {
  dateObject.setDate(dateObject.getDate() + days)
  return dateObject
}
