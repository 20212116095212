<template>
  <div>
    <PageHeader :text="project.name" />
    <ProjectDescription :project="project" class="mb-8" />

    <div class="mb-8">
      <GvsFinishNosologiaProjectCard
        v-for="nosologia in nosologies"
        :key="nosologia.id"
        :projectNosologia="nosologia"
        @update="throwUpdate"
        @zero-alert="setHasZero"
      />
    </div>

    <div v-if="nextStage.disabled" class="body-2 red--text">
      {{ nextStage.errorMessage }}
    </div>
    <v-btn
      color="primary"
      @click="onNextStageCLick"
      :disabled="nextStage.disabled"
      >Отправить в НМИЦ</v-btn
    >
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue'
import ProjectDescription from '@/components/project/ProjectDescription.vue'
import GvsFinishNosologiaProjectCard from '@/components/project/stages/gvsFinish/GvsFinishNosologiaProjectCard.vue'

import { toNextStage } from '@/lib/requestsAPI'
import { routeParams } from '@/mixins/routeParams'
import { sendDataWithToastNoRedirect } from '@/mixins/sendDataWithToastNORedirect'

import { USER_NMIC_ID } from '@/store/const/userRoles'

export default {
  data() {
    return {
      zeroCriteriaNosologiaName: '',
    }
  },
  props: ['project'],
  mixins: [routeParams, sendDataWithToastNoRedirect],
  components: {
    PageHeader,
    ProjectDescription,
    GvsFinishNosologiaProjectCard,
  },
  computed: {
    nosologies() {
      return this.project.projectNosologies
    },
    nextStage() {
      const { projectNosologies } = this.project
      const res = { errorMessage: '', disabled: true }

      if (projectNosologies.length === 0) {
        res.errorMessage =
          'В проекте должно быть хотя бы одно заболевание (состояние)'
        return res
      }

      for (let n of projectNosologies) {
        const { name } = n.nosology

        if (n.is_criteria_check_needed) {
          res.errorMessage = `Проверьте критерии у заболевания «${name}» и отметьте, что критерии выбраны правильно.`
          return res
        }

        if (n.is_users_check_needed) {
          res.errorMessage = `Проверьте, что для заболевания «${name}» правильно выбраны НМИЦ и отметьте это.`
          return res
        }

        const hasSomeNMIC = n.userProjectNosologies.some(
          upn => upn.user.role_id === USER_NMIC_ID
        )
        if (!hasSomeNMIC) {
          res.errorMessage = `Не задан ни один НМИЦ для заболевания (состояния) «${name}»`
          return res
        }

        if (this.zeroCriteriaNosologiaName) {
          res.errorMessage = `У заболевания (состояния) «${this.zeroCriteriaNosologiaName}» не проходит ни один критерий`
          return res
        }
      }

      res.disabled = false
      return res
    },
  },
  methods: {
    setHasZero(name) {
      this.zeroCriteriaNosologiaName = name
    },
    onNextStageCLick() {
      this.sendDataWithToastNoRedirect({
        action: toNextStage,
        data: this.projectId,
        successMessage: 'Отправлен в работу',
        errorMessage: 'Ошибка отправки в работу',
        onSuccess: () => this.throwUpdate(),
      })
    },
    throwUpdate() {
      this.$emit('update')
    },
  },
}
</script>
