<template>
  <div>
    <v-btn class="hide" text x-small @click="onOpen"
      ><v-icon x-small class="mr-1">mdi-eye</v-icon>Посомтреть</v-btn
    >

    <v-dialog v-model="dialog" max-width="600" @click:outside="onCancel">
      <v-card class="pa-4">
        <TitleAndDataLine label="Проект" :value="projectName" />
        <TitleAndDataLine
          label="Заболевание (состояние)"
          :value="nosologia.name"
        />
        <div class="font-weight-bold mt-4">Критерии качества</div>
        <div class="mb-4">
          Тут должен быть просмотр критериев, но сейчас его нет. Отвалился пока
          оптимизировали :-) Критерии можно посмотреть по соседней кнопке
          «редактировать». (Просто посмотреть, редактировать не обязательно). Но
          если без отдельного просмотра грустно-плохо-неудобно — пишите
          разработчикам.
        </div>
        <v-btn @click="onCancel">Закрыть</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'

export default {
  data() {
    return {
      dialog: false,
    }
  },
  props: ['nosologia', 'projectName'],
  components: {
    TitleAndDataLine,
  },
  methods: {
    onOpen() {
      this.dialog = true
    },
    onCancel() {
      this.dialog = false
    },
  },
}
</script>
