<template>
  <span>
    <span class="tip" @click="openDialog">Показать маршрут</span>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>Описание этапов</v-card-title>
        <div class="px-6 pb-16">
          <div :class="{ active: isActive(1) }">
            <span class="font-weight-bold">Черновик.&nbsp;</span>
            <span>Администратор создает черновик проекта</span>
          </div>

          <div class="title ml-16">↓</div>

          <div class="grey--text">
            Администратор нажимает кнопку перевода на след этап
          </div>

          <div class="title ml-16">↓</div>

          <div :class="{ active: isActive(2) }">
            <span class="font-weight-bold">Работают ГВС.&nbsp;</span>
            <span>ГВС отвечают на вопросы.&nbsp;</span>
            <span>Администратор видит их прогресс.&nbsp;</span>
          </div>

          <div class="title ml-16">↓</div>

          <div class="grey--text">Все ГВС закончили отвечать</div>

          <div class="title ml-16">↓</div>

          <div>
            <span class="font-weight-bold">Проверка ответов.&nbsp;</span>
            <span
              >Администратор выбирает какие критерии отправить
              дальше.&nbsp;</span
            >
          </div>

          <div class="title ml-16">↓</div>

          <div class="grey--text">
            Администратор нажимает кнопку перевода на следующий этап
          </div>

          <div class="title ml-16">↓</div>

          <div>
            <span class="font-weight-bold">Работают НМИЦ.&nbsp;</span>
            <span>Работают НМИЦ и внешние эксперты.&nbsp;</span>
          </div>

          <div class="title ml-16">↓</div>

          <div class="grey--text">...Продолжение следует</div>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  props: ['stageId'],
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    openDialog() {
      this.dialog = true
    },
    isActive(n) {
      return n === this.stageId
    },
  },
}
</script>

<style scoped>
.active {
  border: 1px solid red;
  padding: 8px;
  border-radius: 4px;
}
.tip {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
}
</style>
