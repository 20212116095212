<template>
  <div>
    <PageHeader :text="project.name" />

    <ProjectDescription class="mb-12" :project="project" />

    <DownloadProjectResult :projectName="project.name" />
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue'
import ProjectDescription from '@/components/project/ProjectDescription.vue'
import DownloadProjectResult from '@/components/buttons/DownloadProjectResult.vue'

export default {
  props: ['project'],
  components: { PageHeader, ProjectDescription, DownloadProjectResult },
}
</script>
