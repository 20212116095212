<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <component
        :is="stageComponentName"
        :project="project"
        @update="update"
      ></component>
    </div>
    <div v-else>Нет проекта</div>
  </DisplayLoading>
</template>

<script>
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import StageDraftContent from '@/components/project/stages/draft/StageDraftContent.vue'
import StageGVSContent from '@/components/project/stages/gvs/StageGVSContent.vue'
import StageNMICContent from '@/components/project/stages/nmic/StageNMICContent.vue'
import StageGVSFinishedContent from '@/components/project/stages/gvsFinish/StageGVSFinishedContent.vue'
import StageFullFinishedContent from '@/components/project/stages/finished/StageFullFinishedContent.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { API_PROJECTS } from '@/store/const/apiPaths'
import { PROJECT_STAGES } from '@/store/const/projectStages'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю проект',
    }
  },
  components: {
    DisplayLoading,
    StageDraftContent,
    StageGVSContent,
    StageGVSFinishedContent,
    StageNMICContent,
    StageFullFinishedContent,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    loadUrl() {
      return `${API_PROJECTS}/${this.projectId}`
    },
    errorMessage() {
      return `Не удалось загрузить данные для проекта: ${this.projectId}`
    },
    project() {
      document.title =
        this.pageData.name || 'Оценка критериев качества медицинской помощи'
      return this.pageData
    },
    stageComponentName() {
      return PROJECT_STAGES[this.project.stage_id].componentName
    },
  },
}
</script>
