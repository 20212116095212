export const PROJECT_STAGE_DRAFT = 1
export const PROJECT_STAGE_GVS = 2
export const PROJECT_STAGE_GVS_FINISH = 3
export const PROJECT_STAGE_NMIC = 4
export const PROJECT_STAGE_FULL_FINISH = 5
export const PROJECT_STAGE_ARCHIVE = 6

export const PROJECT_STAGES = {
  [PROJECT_STAGE_DRAFT]: {
    name: 'Черновик',
    componentName: 'StageDraftContent',
  },
  [PROJECT_STAGE_GVS]: {
    name: 'В работе у ГВС',
    componentName: 'StageGVSContent',
    answerComponentName: 'PreviewGVSNosologiaAnswers',
    external: true,
  },
  [PROJECT_STAGE_GVS_FINISH]: {
    name: 'ГВС закончили',
    componentName: 'StageGVSFinishedContent',
  },
  [PROJECT_STAGE_NMIC]: {
    name: 'Работают НМИЦ',
    componentName: 'StageNMICContent',
    answerComponentName: 'PreviewNMICNosologiaAnswers',
    external: true,
  },
  [PROJECT_STAGE_FULL_FINISH]: {
    name: 'Завершен',
    componentName: 'StageFullFinishedContent',
  },
  [PROJECT_STAGE_ARCHIVE]: {
    name: 'В архиве',
    componentName: 'StageFullFinishedContent',
  },
}
