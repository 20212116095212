<template>
  <v-card class="mb-2 pa-4">
    <div class="d-flex mb-2">
      <div class="body-1 mr-auto">{{ nosologia.name }}</div>
      <v-btn
        text
        x-small
        @click="onDelete"
        :loading="deleting"
        :disabled="hasCriteries"
        ><v-icon x-small class="mr-1">mdi-close</v-icon>Удалить из
        проекта</v-btn
      >
    </div>

    <!-- Блок ГВС -->
    <div class="mb-4">
      <TitleAndDataLine label="ГВС" :value="gvsNames" class="mb-2" />

      <Alert
        text="ГВС выбраны автоматически. Проверьте все ли верно?"
        buttonText="Все правильно"
        v-if="needUserCheck"
        class="mb-2"
        @close="onCloseAlert"
      />

      <v-btn text x-small @click="onEditGVS"
        ><v-icon x-small class="mr-1">mdi-pencil</v-icon> Выбрать ГВС
      </v-btn>
    </div>

    <!-- Блок критериев -->
    <div class="d-flex align-baseline mb-2">
      <div class="mr-2 min-w-30">Критериев: {{ displayNumberOfCriteries }}</div>

      <ShowCriteria
        :nosologia="nosologia"
        :criteries="criteries"
        :projectName="projectName"
      />

      <v-btn text x-small @click="onEditCriteria"
        ><v-icon x-small class="mr-1">mdi-pencil</v-icon> {{ editCriteriaText }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import ShowCriteria from '@/components/project/ShowCriteria.vue'
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'
import Alert from '@/components/common/Alert.vue'

import { routeParams } from '@/mixins/routeParams'
import { sendDataWithToastNoRedirect } from '../../mixins/sendDataWithToastNORedirect'

import { unpinNosologia, markUsersAsChecked } from '@/lib/requestsAPI'

import { NOSOLOGIA_SHORT, CRITERIA, SELECT_GVS } from '../../store/const/path'
import { USER_GVS_ID } from '@/store/const/userRoles'

export default {
  props: ['projectNosologia', 'projectName'],
  data() {
    return {
      deleting: false,
    }
  },
  mixins: [routeParams, sendDataWithToastNoRedirect],
  components: {
    ShowCriteria,
    TitleAndDataLine,
    Alert,
  },
  computed: {
    nosologia() {
      return this.projectNosologia.nosology
    },
    criteries() {
      return this.projectNosologia.criteria
    },
    users() {
      return this.projectNosologia.userProjectNosologies.map(upn => upn.user)
    },
    projectNosologiaId() {
      return this.projectNosologia.id
    },
    needUserCheck() {
      return this.projectNosologia.is_users_check_needed
    },
    hasCriteries() {
      return !!this.projectNosologia.criteria_count
    },
    displayNumberOfCriteries() {
      return this.hasCriteries ? this.projectNosologia.criteria_count : 'нет'
    },
    editCriteriaText() {
      return this.hasCriteries ? 'Редактировать критерии' : 'Добавить критерии'
    },
    gvsNames() {
      return this.users
        .filter(u => u.role_id === USER_GVS_ID)
        .map(u => u.fio)
        .join(', ')
    },
  },
  methods: {
    onDelete() {
      this.deleting = true
      this.sendDataWithToastNoRedirect({
        action: unpinNosologia,
        onSuccess: this.onSuccess,
        onFinally: this.onFinally,
        data: {
          project_id: this.projectId,
          nosology_id: this.nosologia.id,
        },
        successMessage: 'Открепили от проекта',
        errorMessage: `Не удалось открепить заболевание (состояние) ${this.nosologia.id} от проекта ${this.projectId}`,
      })
    },
    onCloseAlert() {
      this.sendDataWithToastNoRedirect({
        action: () => markUsersAsChecked(this.projectNosologiaId),
        onSuccess: this.onSuccess,
        successMessage: false,
        errorMessage: `Не удалось сбросить флаг с ${this.projectNosologiaId}`,
      })
    },
    onSuccess() {
      this.$emit('update')
    },
    onFinally() {
      this.deleting = false
    },
    onEditCriteria() {
      this.$router.push(
        `${this.$route.path}/${NOSOLOGIA_SHORT}-${this.nosologia.id}/${CRITERIA}`
      )
    },
    onEditGVS() {
      this.alert = false
      this.$router.push(
        `${this.$route.path}/${NOSOLOGIA_SHORT}-${this.nosologia.id}/${SELECT_GVS}`
      )
    },
  },
}
</script>

<style scoped>
.min-w-30 {
  min-width: 120px;
}
.alert {
  color: rgba(251, 140, 0, 1);
  background-color: rgba(251, 140, 0, 0.12);
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 16px;
  border-radius: 4px;
}
</style>
