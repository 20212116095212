// import { API_REGISTRATION } from '@/store/const/apiPaths'

const FUNCTION = 'function'
const STRING = 'string'

export const sendFormWithToastAndRedirect = {
  methods: {
    async sendFormWithToastAndRedirect({
      action,
      data,
      successPath,
      successMessage,
      errorMessage,
      updateSuccessPath = false,
    }) {
      try {
        const id = await action(data)
        if (updateSuccessPath) successPath += id
        this.$router.push(successPath)
        this.$toast.success(successMessage)
      } catch (e) {
        const mainMessage = errorMessage + '\n' + e.message
        const additionalMessage = this.getAdditionalMessage(e)
        const fullMessage = additionalMessage
          ? mainMessage + '\n' + additionalMessage
          : mainMessage
        this.$toast.error(fullMessage)
      } finally {
        if (typeof this.onCancel === FUNCTION) this.onCancel()
      }
    },
    getAdditionalMessage(e) {
      console.log(e.response)
      if (!e.response) return false
      if (!e.response.data) return false
      const { message } = e.response.data
      if (!message) return false
      // В сообщении может быть массив поэтому проверяем
      if (typeof message === STRING) {
        return message.slice(0, 50)
      }
      if (Array.isArray(message)) return message[0].slice(0, 50)
    },
  },
}
